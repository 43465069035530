<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <vx-card
          v-if="!isLoading"
          title="Topup Fund (ใช้ได้เฉพาะช่วงเวลา 02:00 - 22:50)"
        >
          <div class="vx-row">
            <!-- type -->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="vs-input--label">Type</label>
              <vs-select class="w-full mt-4" v-model="makePaymentType">
                <vs-select-item
                  :key="index"
                  :value="item.val"
                  :text="item.name"
                  v-for="(item, index) in makePaymentTypeOptions"
                />
              </vs-select>
              <br />
              <div v-if="paymentInfo && paymentInfo.length > 0" class="text">
                *โอนมาที่ {{ paymentInfo[0].bank_name }} ชื่อบัญชี
                {{ paymentInfo[0].bank_acc_name }} เลขที่บัญชี
                {{ paymentInfo[0].bank_acc_no }}
              </div>
              <div v-else class="text">
                {{ bankPlaceHolder }}
              </div>
              <br />
              <div v-if="makePaymentType === 'CDM'" class="mt-2 mb-8 text">
                *กรุณาถ่ายสลิปฝากเงิน และส่งกลับมาให้แอดมิน
                เพื่อยืนยันรายการด้วยคะ
                <br />
                *ห้ามรวมยอดสลิป สร้าง 1 รายการ ต่อ 1 สลิป ตามยอดที่ฝากเงิน
              </div>
            </div>
            <!-- fake -->
            <div
              class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
            ></div>
            <!-- Account Name -->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="vs-input--label">Bank Account Name</label>
              <vs-input
                class="w-full mt-4"
                type="text"
                v-model="makePaymentBankAccountName"
                :disabled="makePaymentType === 'CDM'"
              />
              <br />
            </div>
            <!-- Bank No -->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="vs-input--label">Bank Account No *</label>
              <vs-input
                class="w-full mt-4"
                type="number"
                v-model="makePaymentBankAccountNo"
                :disabled="makePaymentType === 'CDM'"
              />
              <br />
            </div>
            <!-- Bank Name -->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="vs-input--label">Bank Name *</label>
              <vs-select
                autocomplete
                class="w-full mt-4"
                v-model="makePaymentBankName"
                :disabled="makePaymentType === 'CDM'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.val"
                  :text="item.name"
                  v-for="(item, index) in bankOptions"
                />
              </vs-select>
            </div>
            <!-- Amount -->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="vs-input--label"
                >จำนวนเงิน * (10,000 - 1,999,999)</label
              >
              <vs-input
                class="w-full mt-4"
                type="number"
                v-model="makePaymentAmount"
              />
              <br />
            </div>
            <!-- MDR -->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="vs-input--label">MDR (1.10%)</label>
              <vs-input
                class="w-full mt-4"
                disabled
                type="text"
                :value="currency(MDR)"
              />
              <br />
            </div>
            <!-- netAmount -->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="vs-input--label">Net Received</label>
              <vs-input
                class="w-full mt-4"
                disabled
                type="text"
                :value="currency(netAmount)"
              />
              <br />
            </div>
          </div>
          <vs-button
            class="mr-2"
            color="success"
            @click="submitPayment"
            :disabled="isAmountInvalid"
            v-if="!isReadyToTransfer"
            >Submit</vs-button
          >
          <div v-else>
            <h3>{{transferPaymentResult.formattedOutput.transferToBank}}</h3>
            <h4 class="text-success">ธนาคาร: {{ transferPaymentResult.formattedOutput.bank }} เลขที่: {{ transferPaymentResult.formattedOutput.accountNumber }}</h4>
            <h4 class="text-warning">จำนวนเงิน: {{ transferPaymentResult.formattedOutput.price }} บาท</h4>

            <table class="topup-detail-table">
              <tbody>
                <tr>
                  <td>Ref ID</td>
                  <td>{{ transferPaymentResult.formattedOutput.refID }}</td>
                </tr>
                <tr>
                  <td>Order ID</td>
                  <td>{{ transferPaymentResult.formattedOutput.orderID }}</td>
                </tr>
                <tr>
                  <td>Price</td>
                  <td>{{ transferPaymentResult.formattedOutput.price }} บาท</td>
                </tr>
                <tr>
                  <td>Transfer To Bank</td>
                  <td>
                    ธนาคาร: {{ transferPaymentResult.formattedOutput.bank }}<br />1
                    เลขที่: {{ transferPaymentResult.formattedOutput.accountNumber }}
                  </td>
                </tr>
                <tr>
                  <td>Timeout</td>
                  <td>{{ transferPaymentResult.formattedOutput.timeout }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <vs-button color="primary" @click="clearSearchBox()">Clear</vs-button>
        </vx-card>
        <vx-card v-else> Loading... </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    StatisticsCardLine,
    vSelect,
    flatPickr
  },
  data () {
    return {
      isReadyToTransfer: false,
      cookie: null,
      isLoading: true,
      bankPlaceHolder: 'ระบบกำลังดึงข้อมูล กรุณารอสักครู่...',
      deposit: 0.0,
      withdraw: 0.0,
      startDate: this.moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: this.moment().format('YYYY-MM-DD HH:mm:ss'),
      makePaymentType: 'TRANSFER',
      makePaymentTypeOptions: [
        {
          val: 'TRANSFER',
          name: 'TRANSFER',
          sentname: 'TRANSFER'
        },
        {
          val: 'CDM',
          name: 'ฝากเงินสด (CDM)',
          sentname: 'ฝากเงินสด (CDM)'
        }
      ],
      makePaymentBankAccountName: null,
      makePaymentBankAccountNo: null,
      makePaymentBankName: '014',
      bankOptions: [
        {
          val: '034',
          name: 'BAAC-ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'
        },
        {
          val: '025',
          name: 'BAY-ธนาคารกรุงศรีอยุธยา'
        },
        {
          val: '002',
          name: 'BBL-ธนาคารกรุงเทพ'
        },
        {
          val: '022',
          name: 'CIMB-ธนาคารซีไอเอ็มบีไทย'
        },
        {
          val: '017',
          name: 'CITI-ซิตี้แบงก์'
        },
        {
          val: '033',
          name: 'GHB-ธนาคารอาคารสงเคราะห์ (ธอส.)'
        },
        {
          val: '030',
          name: 'GSB-ธนาคารออมสิน'
        },
        {
          val: '031',
          name: 'HSBC-ฮ่องกงและเซี่ยงไฮ้แบงกิ้งคอร์ปอเรชั่น'
        },
        {
          val: '004',
          name: 'KBANK-ธนาคารกสิกรไทย'
        },
        {
          val: '069',
          name: 'KKP-ธนาคารเกียรตินาคิน'
        },
        {
          val: '006',
          name: 'KTB-ธนาคารกรุงไทย'
        },
        {
          val: '073',
          name: 'LHBANK-ธนาคารแลนด์ แอนด์ เฮาส์'
        },
        {
          val: '039',
          name: 'MHCB-ธนาคารมิซูโฮ คอร ปอเรต จํากัด'
        },
        {
          val: '014',
          name: 'SCB-ไทยพาณิชย์'
        },
        {
          val: '020',
          name: 'SCBT-ธนาคารสแตนดาร์ดชาร์เตอร์ด'
        },
        {
          val: '018',
          name: 'SMBC-ธนาคาร ซูมิโตโม มิตซุย แบงกิ้ง'
        },
        {
          val: '071',
          name: 'TCRB-ธนาคารไทยเครดิต เพื่อรายย่อย'
        },
        {
          val: '067',
          name: 'TISCO-ธนาคารทิสโก้'
        },
        {
          val: '801',
          name: 'TRUE-True Wallet'
        },
        {
          val: '011',
          name: 'TTB-ธนาคารทหารไทย'
        },
        {
          val: '024',
          name: 'UOB-ธนาคารยูโอบี'
        }
      ],
      makePaymentAmount: 0,
      config: {
        enableTime: true
      },
      paymentInfo: {},
      transferPaymentResult: {}
    }
  },
  async mounted () {
    await this.getPaymentInfo()
    this.isLoading = false
  },
  methods: {
    async clearSearchBox () {
      this.paymentInfo = {}
      this.makePaymentAmount = 0
      this.makePaymentBankAccountName = null
      this.makePaymentBankAccountNo = null
      this.makePaymentBankName = ''
      this.isReadyToTransfer = false
      this.transferPaymentResult = {}
      await this.getPaymentInfo()
    },
    async getPaymentInfo () {
      axios
        .get('/payment/topup/info')
        .then(response => {
          if (response.data.error === 0) {
            this.paymentInfo = response.data.result
            this.cookie = response.data.cookie
          } else {
            this.$vs.notify({
              time: 10000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'ไม่สามารถดึงข้อมูลได้ในขณะนี้',
              text: 'กรุณาลองใหม่อีกครั้งในภายหลัง'
            })
            this.bankPlaceHolder = 'กรุณาลอง Refresh ใหม่อีกครั้ง'
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    currency (amount) {
      amount = (amount * 1.0).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
    },
    async submitPayment () {
      const {
        makePaymentType,
        makePaymentBankAccountName,
        makePaymentBankAccountNo,
        makePaymentBankName,
        makePaymentAmount,
        MDR,
        netAmount
      } = this

      let payload = {
        makePaymentType,
        makePaymentBankAccountName,
        makePaymentBankAccountNo,
        makePaymentBankName,
        makePaymentAmount,
        makePaymentMDRAmount: MDR,
        makePaymentNetAmount: netAmount,
        cookie: this.cookie
      }

      if (makePaymentType === 'CDM') {
        payload = {
          makePaymentType,
          makePaymentAmount,
          makePaymentMDRAmount: MDR,
          makePaymentNetAmount: netAmount,
          cookie: this.cookie
        }
      }

      try {
        const response = await axios.post('/payment/topup/transfer', payload)

        // Handle the response data
        console.log(response.data)

        this.transferPaymentResult = response.data
        this.isReadyToTransfer = true
      } catch (error) {
        // Handle the error
        console.error(error)
      }
    }
  },
  computed: {
    isAmountInvalid () {
      const amount = parseFloat(this.makePaymentAmount)
      const minAmount = 10000
      const maxAmount = 1999999
      return isNaN(amount) || amount < minAmount || amount > maxAmount
    },
    MDR () {
      const amount = parseFloat(this.makePaymentAmount)
      if (isNaN(amount)) {
        return 0
      }

      return (parseFloat(amount) * 0.011).toFixed(2)
    },
    netAmount () {
      const amount = parseFloat(this.makePaymentAmount)
      if (isNaN(amount)) {
        return 0
      }

      return parseFloat(amount) - this.MDR
    }
  }
}
</script>

<style scoped>
.b {
  border-radius: 5px;
  padding: 5px;
  /* Add padding here */
  color: white;
  background-color: #792cffde;
}

.b-success {
  background-color: #03c837de;
}

.b-failed {
  background-color: #ff0000de;
}

.b-create {
  background-color: #ffb300de;
}

.text {
  color: red;
  font-size: 15px;
}

.topup-detail-table {
  border-collapse: collapse;
  border: 1px solid #e5e5e5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.topup-detail-table th,
.topup-detail-table td {
  border: 1px solid #e5e5e5;
  padding: 8px;
}

.topup-detail-table th {
  background-color: #f5f5f5;
}
</style>
